import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  fetchUserApi,
  getCreatorWorkspaces,
  getOrganizationApi,
} from '../../helper/api';
import { useParams } from 'react-router-dom';
import { isZyncStaff } from '../../helper/isZyncStaff';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const useOrganizationPage = () => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  const { organizationId } = useParams();
  const [organization, setOrganization] = useState(null);
  const [workspaces, setWorkspaces] = useState(null);
  const [owner, setOwner] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        const data = await getOrganizationApi(organizationId);
        setOrganization(data);
      } finally {
        setLoading(false);
      }
    };
    initialize();
  }, [organizationId]);

  const ownerEmail = organization?.ownerId;
  useEffect(() => {
    const getWorkspaces = async () => {
      if (!ownerEmail) return;
      const owner = await fetchUserApi(ownerEmail);
      const data = await getCreatorWorkspaces(ownerEmail);
      setWorkspaces(data);
      setOwner(owner);
    };
    getWorkspaces();
  }, [ownerEmail]);

  const handleCreateWorkspace = useCallback(async () => {
    try {
      const user = await fetchUserApi(organization.ownerId);

      if (!user || !organization) {
        return;
      }

      const workspaces = await getCreatorWorkspaces(ownerEmail);
      const limitedWorkspacesLength = workspaces.filter(
        (ws) => ws.plan === 'free' || ws.isTrial
      ).length;

      if (limitedWorkspacesLength >= organization.trialWorkspacesLimit) {
        return {
          error: `Sorry, you’ve reached your limit of ${organization.trialWorkspacesLimit} free and trial workspaces.`,
        };
      }

      await axios.post(`/api/quick-start-workspace`, {
        workspace: {
          workspaceName: `Trial Workspace`,
          logoUrl: organization.orgLogoUrl,
        },
        speaker: {
          email: organization.ownerId,
          name: user.userName,
          contentPillars: [],
          headline: user.bio,
        },
        topic: {
          recentArticle: '',
        },
      });

      const data = await getCreatorWorkspaces(ownerEmail);
      setWorkspaces(data);
    } catch (error) {
    } finally {
    }
  }, [organization, ownerEmail]);

  const authorized = organization?.ownerId === userId || isZyncStaff(userId);

  const initialError = useMemo(() => {
    const limitedWorkspacesLength = workspaces?.filter(
      (ws) => ws.plan === 'free' || ws.isTrial
    )?.length;

    if (limitedWorkspacesLength >= organization?.trialWorkspacesLimit) {
      return `Sorry, you’ve reached your limit of ${organization.trialWorkspacesLimit} free and trial workspaces.`;
    } else {
      return '';
    }
  }, [organization, workspaces]);

  return {
    userId,
    organization,
    workspaces,
    owner,
    authorized,
    loading,
    handleCreateWorkspace,
    initialError,
  };
};
