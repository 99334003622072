/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useState,
  createElement,
  useEffect,
  useRef,
} from 'react';
import { Provider, shallowEqual, useSelector } from 'react-redux';
import { uploadVideoToMux } from '../helper/localRecordingStream';
import { notifyUser } from '../components/authoring/hooks';
import { sendEvent } from '../helper/api';
import ReactDOM from 'react-dom';
import { Button } from '../components/common/Button';
import { WarningTriangleIcon } from '../components/icons/WarningTriangleIcon';
import { createStore } from 'redux';
import rootReducer from '../reducers';
import { ModalBody, ModalTitle, ModalWindow } from '../components/Modal';
import { isFirefox, isSafari, makeRandomId } from '../helper';
import {
  fromError,
  logerror,
  loginfo,
  logwarn,
} from '../helper/contextualLogger';
import { delay } from '../helper/exponentialFetchRetry';
import hark from 'hark';
import mixpanel from 'mixpanel-browser';
import { wait } from 'zync-common/helper/wait';

export const checkIsUploading = () => {
  if (window?.completedRecordings < window?.startedRecordings) {
    return true;
  }

  return false;
};

const config = {
  audioBitsPerSecond: 48_000, // Sample rate 48khz is considered a standard for high quality productions,
  videoBitsPerSecond: 8_000_000, // ideal kbps per second for 4k falls between 35,000,000 to 45,000,000
  timeSlice: 1_000, // media stream chunks are saved using this interval
};

const LOCAL_RECORDING_DOWNLOAD_ID = 'local-recording-download';
const MIC_THRESHOLD = -65;
const modalManager = (containerHtmlId, classNames = 'absolute') => ({
  openModal(modalClass, props) {
    // Create container for the modal to be rendered into
    const renderContainer = document.createElement('div');
    renderContainer.classList.add(classNames);
    renderContainer.id = containerHtmlId;
    renderContainer.style.zIndex = 9999;
    document.body.prepend(renderContainer);

    // Create & render component
    const modalInst = createElement(modalClass, { ...props, renderContainer });

    ReactDOM.render(modalInst, renderContainer);

    return () => this.unmountModal(renderContainer);
  },
  unmountModal(callback) {
    const renderContainer = document.getElementById(containerHtmlId);
    ReactDOM.unmountComponentAtNode(renderContainer);

    try {
      renderContainer.parentNode.removeChild(renderContainer);
    } catch (error) {}

    if (callback) {
      callback();
    }
  },
});

const PROGRESS_BAR_FILE_SAVED_LOCALLY = 'progress-bar-file-saved-locally';

const ConfirmationModal = ({ blob }) => {
  const [isLastWarning, setIsLastWarning] = useState(false);

  const store = createStore(rootReducer);
  const handleClose = () =>
    modalManager(LOCAL_RECORDING_DOWNLOAD_ID).unmountModal();

  const handleDownloadVideoFileLocally = () => {
    const url = URL.createObjectURL(blob);

    // Create a link element and programmatically click it to download the file
    const link = document.createElement('a');
    link.href = url;
    link.download = 'recorded-video.webm';
    link.click();

    const successText = document.getElementById(
      PROGRESS_BAR_FILE_SAVED_LOCALLY
    );

    if (successText) {
      successText.classList.remove('invisible');
    }

    handleClose();
  };

  return (
    <Provider store={store}>
      <ModalWindow size="md" onCancel={handleClose} showCancel={false}>
        <ModalTitle>
          <div className="flex gap-2 items-center mb-10">
            <WarningTriangleIcon /> Attention
          </div>
        </ModalTitle>
        <ModalBody>
          <p className="text-blue-gray text-sm -mt-6 mb-10">
            {isLastWarning
              ? 'You will lose a high definition recording of the episode. Are you sure you want to continue?'
              : 'We will record the file locally so you can upload it later. This is useful if the current uploading fails'}
          </p>
          <div className="flex gap-2">
            {!isLastWarning && (
              <Button
                onClick={() => setIsLastWarning(true)}
                color={Button.colors.WHITE}
                padding={Button.padding.MEDIUM}
                size={Button.sizes.FULL}
              >
                <span className="text-sm font-bold text-blue-dark font-jakarta">
                  No
                </span>
              </Button>
            )}
            {!isLastWarning && (
              <Button
                onClick={handleDownloadVideoFileLocally}
                color={Button.colors.PURPLE}
                padding={Button.padding.MEDIUM}
                size={Button.sizes.FULL}
              >
                <span className="text-base font-medium font-jakarta">Ok</span>
              </Button>
            )}
            {isLastWarning && (
              <Button
                onClick={handleClose}
                color={Button.colors.RED}
                padding={Button.padding.MEDIUM}
                size={Button.sizes.FULL}
              >
                <span className="text-base font-medium">Lose Recording</span>
              </Button>
            )}
            {isLastWarning && (
              <Button
                onClick={handleDownloadVideoFileLocally}
                color={Button.colors.WHITE}
                padding={Button.padding.MEDIUM}
                size={Button.sizes.FULL}
              >
                <span className="text-sm font-bold font-jakarta">
                  Save File Locally
                </span>
              </Button>
            )}
          </div>
        </ModalBody>
      </ModalWindow>
    </Provider>
  );
};

const openLocalDownloadPopup = (blob) => {
  modalManager(LOCAL_RECORDING_DOWNLOAD_ID).openModal(ConfirmationModal, {
    blob,
  });
};

const updateNumUploads = (mode) => {
  switch (mode) {
    case 'completed':
      window.completedRecordings++;
      break;
    case 'started': {
      window.startedRecordings++;
      break;
    }
    default:
      break;
  }
};

window.completedRecordings = 0;
window.startedRecordings = 0;

window.updateNumUploads = updateNumUploads;

export const useChunkedLocalRecording = ({
  meetingSeriesId,
  workspaceId,
  isSoloEpisode,
  useHdResolution = false,
}) => {
  const userId = useSelector((_st) => _st.auth.user?.userId);
  const localTrackState = useSelector((state) => state.callState.tracks.local);
  const tracks = useSelector(
    (state) => state.callState.tracks.allUsers,
    shallowEqual
  );
  const wentLive = useSelector((state) => state.meetingState.state?.wentLive);
  const meetingId = useSelector((state) => state.meetingState.meetingId);
  const currentSceneIndex = useSelector(
    (state) => state.meetingState.state?.slides?.currentSlideIndex
  );

  const activeMeetingId = useRef(null);

  const [isLocalRecording, setIsLocalRecording] = useState(false);
  const [wasLocalRecording, setWasLocalRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [volumeEvents, setVolumeEvents] = useState(null);
  const [receivedAudio, setReceivedAudio] = useState(null);

  const volumeEventTimeoutRef = useRef(null);

  const sendStartRecordingAction = useCallback(
    (timestamp) => {
      const startRecordingAction = {
        type: 'START_LOCAL_RECORDING',
        data: { timestamp },
      };

      return sendEvent(userId, meetingId, startRecordingAction);
    },
    [meetingId, userId]
  );

  const sendStopRecordingAction = useCallback(() => {
    const stopRecordingAction = {
      type: 'STOP_LOCAL_RECORDING',
    };

    return sendEvent(userId, meetingId, stopRecordingAction);
  }, [meetingId, userId]);

  const localVideoTrack = localTrackState?.videoTrackState?.track;
  const localAudioTrack = localTrackState?.audioTrackState?.track;

  const userDevicesPrecheck = useCallback(() => {
    if (!localVideoTrack || !localAudioTrack) {
      return false;
    }

    try {
      new MediaStream([localVideoTrack]);
      new MediaStream([localAudioTrack]);
    } catch (error) {
      return false;
    }

    for (const userId in tracks) {
      try {
        const audioTrack = tracks[userId].audioTrackState;
        const videoTrack = tracks[userId].videoTrackState;

        if (
          audioTrack.state !== 'playable' ||
          videoTrack.state !== 'playable'
        ) {
          return false;
        }

        new MediaStream([audioTrack.track]);
        new MediaStream([videoTrack.track]);
      } catch (e) {
        return false;
      }
    }

    return true;
  }, [localAudioTrack, localVideoTrack, tracks]);

  const stopMediaRecorder = (mediaRecorder, chunks) => {
    return new Promise(async (resolve, reject) => {
      mediaRecorder.ondataavailable = function (event) {
        if (event.data && event.data.size > 0) {
          console.log('Last chunks');
          chunks.push(event.data);
        }
      };

      mediaRecorder.onerror = function (event) {
        reject(event.error);
      };

      mediaRecorder.onstop = function () {
        const blob = new Blob(chunks, {
          type: chunks[0]?.type || 'video/webm',
        });
        resolve(blob);
      };

      await wait(1_000);

      mediaRecorder.stop();
    });
  };

  const startRecording = useCallback(async () => {
    // TODO: check if MediaRecorder is supported by client browser: https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder#browser_compatibility

    if (wasLocalRecording) {
      return;
    }

    activeMeetingId.current = meetingId;

    setWasLocalRecording(true);
    setRecordedChunks([]);

    loginfo({ message: 'First local recording attempt', meetingId, userId });

    if (localTrackState) {
      // Create two video elements: one local video track and the other local screen share track
      // Also note that screen share needs to be started BEFORE starting recording in order for it to show up in the recording
      // Can be improved by listening to screen share state change and dynamically adding/removing tracks to MediaRecorder via addTrack() and removeTrack()

      const localVideoTrack = localTrackState.videoTrackState.track;
      const localAudioTrack = localTrackState.audioTrackState.track;

      let stream = null;

      try {
        stream = new MediaStream([localVideoTrack, localAudioTrack]);
      } catch (e) {
        notifyUser(
          'We could not start local recording - did you turn on your camera and microphone?',
          undefined,
          false
        );
        logwarn({
          message: `Local recording failed - user ${userId}'s camera was off`,
          meetingId,
          userId,
        });
        setWasLocalRecording(false);
        return 'error';
      }

      // Adjust as per needed. Note that VP9 support may not be available for all hardware and browsers, so VP8 might be a better default - so highly recommend placing checks to see if VP9 is supported
      const options = {
        audioBitsPerSecond: config.audioBitsPerSecond,
        videoBitsPerSecond: useHdResolution
          ? 4_000_000
          : config.videoBitsPerSecond,
      };

      if (isSafari) {
        options.mimeType = 'video/mp4';
      } else if (isFirefox) {
        options.mimeType = 'video/webm;codecs="vp8,opus"';
      } else {
        options.mimeType = useHdResolution
          ? 'video/webm;codecs="vp8,opus"'
          : 'video/webm;codecs="vp9,opus"';
      }

      const newMediaRecorder = new MediaRecorder(stream, options);
      setMediaRecorder(newMediaRecorder);

      // Start recording
      newMediaRecorder.start(config.timeSlice);
      const timestamp = Date.now();
      await sendStartRecordingAction(timestamp);

      loginfo({
        message:
          'Local recording after mic/camera initialization started successfully',
        meetingId,
        userId,
      });

      setIsLocalRecording(true);

      // Set audio detector
      try {
        const harkObject = hark(
          new MediaStream([localTrackState?.audioTrackState?.persistentTrack]),
          { interval: 1000 }
        );
        setVolumeEvents(harkObject);
      } catch (e) {
        logerror({
          ...fromError(e),
          message: `Error creating audio detector. ${e.message}`,
        });
      }

      newMediaRecorder.onerror = (event) => {
        console.log('MediaRecorder error:', event.error.name);
        logerror({
          ...fromError(event.error),
          message: 'Local recording stopped due to error: ' + event.error.name,
          userId,
          meetingId,
        });
      };

      // Listen for dataavailable event to collect the recorded chunks
      newMediaRecorder.ondataavailable = (e) => {
        console.log('Data available:', e.data);
        setRecordedChunks((prev) => [...prev, e.data]);
      };
    } else {
      loginfo({
        message: 'Tried to local record but we could not find localVideoTrack',
        meetingId,
        userId,
      });
      setWasLocalRecording(false);
      return 'error';
    }
  }, [
    wasLocalRecording,
    meetingId,
    userId,
    localTrackState,
    sendStartRecordingAction,
    useHdResolution,
  ]);

  const stopRecording = useCallback(
    async (segmentId, segmentIndex, doNotUpload = false) => {
      if (mediaRecorder && mediaRecorder.state === 'recording') {
        let blob = '';

        blob = await stopMediaRecorder(mediaRecorder, recordedChunks);

        if (volumeEvents) {
          volumeEvents.stop();
          setVolumeEvents(null);
          clearTimeout(volumeEventTimeoutRef.current);
        }

        setReceivedAudio(null);
        setIsLocalRecording(false);
        setWasLocalRecording(false);

        await delay(1_500);

        const meetingId = activeMeetingId.current;

        loginfo({
          workspaceId,
          meetingSeriesId,
          meetingId,
          userId,
          message: `Stopped local recording normally`,
        });

        if (!doNotUpload) {
          await sendStopRecordingAction();
        }

        if (!wentLive) {
          activeMeetingId.current = null;
          setRecordedChunks([]);
          setMediaRecorder(null);
          return;
        }

        loginfo({
          workspaceId,
          meetingSeriesId,
          meetingId,
          userId,
          message: `Local recording file created. Size: ${blob.size}`,
        });

        if (doNotUpload) {
          return;
        }

        updateNumUploads('started');
        //openLocalDownloadPopup(blob);

        try {
          await uploadVideoToMux(blob, {
            userId,
            workspaceId,
            meetingSeriesId,
            meetingId,
            type: 'local-recording',
            scene: currentSceneIndex,
            time: Date.now(),
            id: segmentId || makeRandomId(3),
            index: segmentIndex,
          });
          updateNumUploads('completed');
        } catch (e) {
          logerror({
            ...fromError(e),
            message: `Tried to upload local recording but failed. ${e.message}`,
          });
        } finally {
        }
      }
    },
    [
      mediaRecorder,
      volumeEvents,
      recordedChunks,
      workspaceId,
      meetingSeriesId,
      userId,
      sendStopRecordingAction,
      wentLive,
      currentSceneIndex,
    ]
  );

  if (isSoloEpisode) {
    window.stopLocalRecording = stopRecording;
  }

  useEffect(() => {
    if (!volumeEvents) return;
    volumeEvents.on('volume_change', function (volume) {
      if (volume > MIC_THRESHOLD) {
        setReceivedAudio(true);
        volumeEvents.stop();
        setVolumeEvents(null);
        clearTimeout(volumeEventTimeoutRef.current);
      }
    });

    return () => {
      if (volumeEvents) {
        volumeEvents.stop();
      }
    };
  }, [volumeEvents]);

  useEffect(() => {
    if (!volumeEvents) return;
    volumeEventTimeoutRef.current = setTimeout(() => {
      setReceivedAudio(false);
      mixpanel.track('Studio: Audio Not Received Notification Shown ', {
        distinct_id: userId,
      });
    }, 4_000);

    return () => {
      clearTimeout(volumeEventTimeoutRef.current);
    };
  }, [userId, volumeEvents]);

  return {
    startRecording,
    stopRecording,
    isLocalRecording,
    userDevicesPrecheck,
    receivedAudio,
  };
};
