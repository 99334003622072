import { capitalizeFirstLetter } from '../../components/Highlights';
import { Button } from '../../components/common/Button';
import { ModalBody, ModalTitle, ModalWindow } from '../../components/Modal';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CreateWorkspaceModal = ({ isOpen, onClose, onConfirm, initialError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleConfirm = async () => {
    setError('');
    setIsLoading(true);

    if (isLoading) {
      return;
    }

    try {
      const result = await onConfirm();

      if (result?.error) {
        setError(result.error);
        setIsLoading(false);
      } else {
        onClose();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <ModalWindow
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      bg="TRANSPARENT"
      withBackgroundBlur={false}
      boxShadow={200}
    >
      <ModalTitle>Create new workspace</ModalTitle>
      <ModalBody>
        <div className="flex gap-4 items-center justify-end">
          {isLoading ? null : (
            <Button onClick={onClose}>
              <span className="text-sm font-medium">Cancel</span>
            </Button>
          )}
          <Button
            color={Button.colors.PURPLE}
            onClick={handleConfirm}
            state={
              isLoading
                ? Button.states.LOADING
                : initialError
                ? Button.states.DISABLED
                : Button.states.DEFAULT
            }
          >
            <span className="text-sm font-medium">Submit</span>
          </Button>
        </div>
        {error || initialError ? (
          <p className="text-xxs text-right text-red font-medium">
            {error || initialError}
          </p>
        ) : null}
      </ModalBody>
    </ModalWindow>
  );
};

const SupportModal = ({ isOpen, onClose }) => {
  return (
    <ModalWindow
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      bg="TRANSPARENT"
      withBackgroundBlur={false}
      boxShadow={200}
    >
      <ModalTitle>Contact Support</ModalTitle>
      <ModalBody>
        <p className="text-sm text-blue-dark mb-4">
          Please contact us at{' '}
          <a href="mailto: support@zync.ai" className="text-blue text-sm">
            support@zync.ai
          </a>{' '}
          to perform this action
        </p>
        <div className="flex gap-4 items-center justify-end">
          <Button
            padding={Button.padding.SMALL}
            color={Button.colors.PURPLE}
            onClick={onClose}
          >
            <span className="text-sm font-medium">OK</span>
          </Button>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};

export const WorkspaceTable = ({
  workspaces = [],
  initialError,
  handleCreateWorkspace,
  canCreateNewWorkspace,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  return (
    <>
      {isConfirmationModalOpen ? (
        <CreateWorkspaceModal
          initialError={initialError}
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={handleCreateWorkspace}
        />
      ) : null}
      {isSupportModalOpen ? (
        <SupportModal
          isOpen={isSupportModalOpen}
          onClose={() => setIsSupportModalOpen(false)}
        />
      ) : null}
      <div className="px-4 sm:px-6 sm:pt-6 lg:px-8 lg:pt-8 border border-[#eee] rounded-lg">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-gray-900">
              Workspaces
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the workspaces in your organization.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none group relative">
            <Button
              state={
                canCreateNewWorkspace
                  ? Button.states.DEFAULT
                  : Button.states.DISABLED
              }
              color={Button.colors.PURPLE}
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              <span className="text-sm font-medium">
                Create New Trial Workspace
              </span>
            </Button>
            {!canCreateNewWorkspace ? (
              <div className="opacity-0 absolute group-hover:opacity-100 text-xs font-medium text-gray-900 bg-white rounded-md text-center border border-blue-gray/10 mt-2 p-2 shadow-400">
                You need to setup payment method first
              </div>
            ) : null}
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-[#aaa]">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-blue-dark sm:pl-0"
                    >
                      Workspace
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-blue-dark"
                    >
                      Payment Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-right text-sm font-semibold text-blue-dark"
                    >
                      <span>Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#ddd]">
                  {workspaces.map((workspace) => (
                    <tr key={workspace.workspaceId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-dark sm:pl-0">
                        <Link
                          to={`/solo?workspaceId=${workspace.workspaceId}`}
                          className="text-sm"
                        >
                          {workspace.name}
                        </Link>
                        <p className="text-blue-gray/50 text-xs">
                          {workspace.workspaceId}
                        </p>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {workspace.isTrial
                          ? 'Trial'
                          : capitalizeFirstLetter(workspace.plan)}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 flex justify-end">
                        {workspace.isTrial ? (
                          <Button
                            color={Button.colors.WHITE_LITE}
                            padding={Button.padding.SMALL}
                            onClick={() => setIsSupportModalOpen(true)}
                          >
                            <span className="text-cyan text-sm">
                              Convert to Paid
                            </span>
                            <span className="sr-only">
                              {workspace.workspaceName}
                            </span>
                          </Button>
                        ) : null}
                        {workspace.plan === 'free' ? (
                          <Button
                            color={Button.colors.WHITE_LITE}
                            padding={Button.padding.SMALL}
                            onClick={() => setIsSupportModalOpen(true)}
                          >
                            <span className="text-orange-light text-sm">
                              Reactivate
                            </span>
                            <span className="sr-only">
                              {workspace.workspaceName}
                            </span>
                          </Button>
                        ) : null}
                        {workspace.plan !== 'free' && !workspace.isTrial ? (
                          <Button
                            color={Button.colors.WHITE_LITE}
                            padding={Button.padding.SMALL}
                            onClick={() => setIsSupportModalOpen(true)}
                          >
                            <span className="text-red text-sm">Deactivate</span>
                            <span className="sr-only">
                              {workspace.workspaceName}
                            </span>
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
