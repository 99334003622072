import React from 'react';
import { EventLink } from '../NewPortal';
import { getTextFromRichText } from 'zync-common/helper/richText';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';
import { getSpeakerInviteUrl } from '../EventLanding/Plan/PlanInviteSpeakerCard';
import classNames from '../../helper/classNames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../components/icons/CopyIcon';
import { CompletedContentKitIcon } from '../../components/icons/CompletedContentKitIcon';
import { CircleAlertIcon } from '../../components/icons/CircleAlertIcon';
import { CaretIconV2 } from '../../components/icons/CaretIconV2';
import { useState } from 'react';
import { RightAngleIcon } from '../../components/icons/RightAngleIcon';
import { useWorkspacePlan } from '../../hooks/useWorkspacePlan';
import { PLANS } from 'zync-common/zyncCustomerPlans';

export const SoloEpisodeCard = ({
  series,
  showImages = true,
  setShowDesktopOnlyModal,
}) => {
  const { plan, isTrial } = useWorkspacePlan();
  const isSoloStarterPlan = plan === PLANS.solo_starter_monthly.id;
  const isFreePlan = plan === PLANS.free.id;

  const { meetingSeriesId, settings, ORSessions = [] } = series || {};

  const {
    isZyncUp = false,
    doNotCountTowardsMonthlyClips = false,
    seekerDetails = null,
    responderDetails = null,
  } = settings || {};

  const isSR = !!seekerDetails && !!responderDetails;
  const isR = !!seekerDetails && !!!responderDetails;
  const showFreePill =
    (isFreePlan || isSoloStarterPlan || isTrial) &&
    doNotCountTowardsMonthlyClips;

  const contentKitStatus = series?.contentKitStatus?.status;
  const completed = Boolean(contentKitStatus); // any status

  const [showORSessions, setShowORSessions] = useState(false);

  return (
    <div className="flex flex-col">
      <article
        className={classNames(
          `flex md:gap-6 gap-2 p-4 pl-6 border border-[#E6E8F0] relative md:items-center md:flex-row flex-col min-h-[100px]`,
          series?.contentKitStatus?.status === 'COMPLETE'
            ? 'bg-[#EBEBEB]'
            : 'bg-[#F2F2F2]',
          showORSessions ? 'rounded-t-[10px]' : 'rounded-[10px]'
        )}
      >
        {(isZyncUp || isR) && (
          <div className="absolute left-0 top-1/2 -translate-y-1/2 h-[75px] w-1.5 bg-[#FF2D2D] rounded-r-[50px]"></div>
        )}
        <div className="flex-[10_0_0%] flex gap-5 items-center">
          {isZyncUp && contentKitStatus === 'COMPLETE' && (
            <button
              className={classNames(
                'transition-transform',
                showORSessions ? 'rotate-90' : ''
              )}
              onClick={() => {
                setShowORSessions((prevState) => !prevState);
              }}
              tabIndex={-1}
            >
              <CaretIconV2 className="fill-[#333333]" />
            </button>
          )}
          <EventLink
            event={series}
            setShowDesktopOnlyModal={setShowDesktopOnlyModal}
            isContentKitCompleted={completed}
          >
            <h2 className="text-lg text-[#333333] font-medium cursor-pointer font-roboto">
              {getTextFromRichText(series.title)}
            </h2>
          </EventLink>
        </div>
        <div
          className={`flex-[8_0_0%] flex items-center flex-wrap justify-end gap-y-2 ${
            showImages ? 'gap-x-4' : 'gap-x-7'
          }`}
        >
          {showFreePill && (
            <div className="w-20 h-7 rounded-[110px] border border-dashed border-[#4C418D] bg-[#EBEDFC] flex justify-center items-center font-roboto font-bold text-[#4C418D] text-xs">
              FREE
            </div>
          )}
          {!completed ? (
            <div className="relative z-100">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  window
                    .open(
                      getSpeakerInviteUrl(
                        series.settings.eventPresenters[0],
                        series.meetingSeriesId,
                        true
                      ),
                      '_blank'
                    )
                    .focus();
                }}
                className={classNames(
                  'w-[130px]  text-white font-robot text-xs font-bold h-10 rounded-lg',
                  isZyncUp || !!seekerDetails ? 'bg-[#FF0000]' : 'bg-purple'
                )}
              >
                Launch Session
              </button>
            </div>
          ) : null}
          {contentKitStatus !== 'COMPLETE' &&
            contentKitStatus !== 'PROCESSING' &&
            contentKitStatus !== 'WAITING FOR INPUT' &&
            contentKitStatus !== 'INCOMPLETE' && (
              <CopyToClipboard
                text={getSpeakerInviteUrl(
                  series.settings.eventPresenters[0],
                  series.meetingSeriesId,
                  false
                )}
              >
                <button className="active:translate-y-0.5" tabIndex={-1}>
                  <CopyIcon />
                </button>
              </CopyToClipboard>
            )}
          {contentKitStatus === 'COMPLETE' ? (
            <>
              {isZyncUp && (
                <div className="rounded-[110px] border border-dashed border-[#4C418D] bg-[#EBEDFC] flex justify-center items-center font-roboto font-bold text-[#4C418D] text-xs px-5 py-1">
                  {ORSessions.length}{' '}
                  {ORSessions.length === 1 ? 'RESPONSE' : 'RESPONSES'}
                </div>
              )}
              <Link
                to={
                  isZyncUp
                    ? `/e/${meetingSeriesId}/details/zyncup?`
                    : `/e/${meetingSeriesId}/details/publish?`
                }
                className={classNames(
                  'flex gap-2.5 items-center rounded-lg justify-center py-2.5 font-roboto text-xs font-bold text-white w-[130px] active:translate-y-1',
                  isZyncUp || isR || isSR ? 'bg-[#FF0000]' : 'bg-purple'
                )}
              >
                {isZyncUp ? (
                  <>
                    <ArrowRightIcon
                      className="fill-white -rotate-45"
                      width={12}
                      height={12}
                    />{' '}
                    ZyncUp
                  </>
                ) : (
                  <>
                    <CompletedContentKitIcon
                      width="20"
                      height="20"
                      stroke="#FFFFFF"
                    />
                    Content Kit
                  </>
                )}
              </Link>
              <span className="w-4 h-10"></span>
            </>
          ) : null}

          {contentKitStatus === 'ERROR' ? (
            <div className="w-[180px]">
              <div className="flex gap-1 items-center mb-2">
                <ArrowRightIcon
                  color="#97A0AF"
                  className="fill-[#97A0AF] -rotate-45"
                  width={12}
                  height={12}
                />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  aria-disabled
                  className="font-jakarta text-xs font-semibold underline text-[#97A0AF] pointer-events-none"
                >
                  Content Kit
                </a>
              </div>
              <p className="text-xxs font-medium text-red">
                Error producing Content kit
              </p>
            </div>
          ) : null}

          {contentKitStatus === 'PROCESSING' ||
          contentKitStatus === 'WAITING_FOR_CALLBACK' ||
          contentKitStatus === 'WAITING FOR INPUT' ||
          contentKitStatus === 'INCOMPLETE' ? (
            <>
              <div
                className={classNames(
                  'flex font-robot text-xxs font-bold text-white rounded-lg h-10 w-[130px] justify-center items-center',
                  isZyncUp || isR ? 'bg-[#FF0000]/50' : 'bg-purple/50'
                )}
              >
                <CircleAlertIcon />
                <span className="text-center w-[98px]">
                  {isZyncUp
                    ? 'Generating ZyncUp Page...'
                    : 'Generating Content Kit..'}
                </span>
              </div>
              <span className="w-4 h-10"></span>
            </>
          ) : null}
        </div>
      </article>
      {isZyncUp && showORSessions && (
        <>
          {ORSessions.length ? (
            ORSessions.map((ms) => {
              const showFreePill = isFreePlan || isSoloStarterPlan || isTrial;

              return (
                <div
                  className="min-h-[100px] w-full bg-[#F2F2F2] rounded-b-[10px] border-t border-[#C6C6C6] flex items-center md:gap-6 gap-2 p-4 pl-6"
                  key={ms.meetingSeriesId}
                >
                  <div className="flex-[10_0_0%] flex gap-5 items-center pl-11">
                    <RightAngleIcon />
                    <EventLink
                      event={ms}
                      setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                      isContentKitCompleted={completed}
                    >
                      <h2 className="text-lg text-[#333333] font-medium cursor-pointer font-roboto">
                        {getTextFromRichText(ms.title)}
                      </h2>
                    </EventLink>
                  </div>
                  <div
                    className={`flex-[8_0_0%] flex items-center flex-wrap justify-end gap-y-2 ${
                      showImages ? 'gap-x-4' : 'gap-x-7'
                    }`}
                  >
                    {showFreePill && (
                      <div className="w-20 h-7 rounded-[110px] border border-dashed border-[#4C418D] bg-[#EBEDFC] flex justify-center items-center font-roboto font-bold text-[#4C418D] text-xs">
                        FREE
                      </div>
                    )}
                    <Link
                      to={`/e/${ms.meetingSeriesId}/details/publish?`}
                      className="flex gap-2.5 items-center rounded-lg justify-center py-2.5 font-roboto text-xs font-bold text-white w-[130px] active:translate-y-1 bg-[#FF0000]"
                    >
                      <CompletedContentKitIcon
                        width="20"
                        height="20"
                        stroke="#FFFFFF"
                      />
                      Content Kit
                    </Link>
                    <span className="w-4 h-10"></span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="min-h-[100px] w-full bg-[#F2F2F2] rounded-b-[10px] border-t border-[#C6C6C6] flex pl-[68px] items-center">
              <RightAngleIcon />
              <div className="w-[69px] h-[69px] bg-[#D9D9D9] rounded-[10px] ml-2"></div>
              <span className="font-roboto text-[#777777] text-lg font-bold ml-5">
                No responses so far
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
