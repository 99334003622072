export const useWorkspaceFlags = (workspace = {}) => {
  const {
    featureFlags: {
      enablePublicContentKit = false,
      useSegments = true,
      useVectorStore = false,
      useHdResolution = false,
      useEnhancedVideoEditor = false,
      useMotionStillAndKeywordLayout = false,
    } = {},
  } = workspace || {};

  return {
    enablePublicContentKit,
    useSegments,
    useVectorStore,
    useHdResolution,
    useEnhancedVideoEditor,
    useMotionStillAndKeywordLayout,
  };
};
